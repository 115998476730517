import React from 'react';

// 定義一個接口來描述每個鏈接的結構
import { Link } from 'react-router-dom';
import { Link as MenuLink } from '../data/data';

interface MenuProps {
    links: MenuLink[];
}

const Menu: React.FC<MenuProps> = ({ links }) => {
    return (
        <nav className="head-menu">
            <ul className="top-menu">
                {links.map((link, index) => (
                    <li key={index} className={`top-menu-item ${index === 0 ? 'left' : ''} ${links.length === index + 1 ? 'right' : ''}`}>
                        <Link to={link.href}>{link.text}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Menu;
