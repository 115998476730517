// 定义 DalleContentItem 接口
export interface DalleContentItem {
    description: string;
    images: string[];
}

// 示例数据，实际项目中可能来自外部源
export const dalleContentItems: DalleContentItem[] = [
    {
        description: "想像一下，天氣很好的海邊，一個大約30歲的女子，穿著輕鬆，戴著太陽眼鏡，躺在沙灘的躺椅上，有遮陽傘，旁邊有一個小桌，桌上放著一盤炸雞，兩瓶碑酒，一杯果汁，吹著海風，看著海，微笑著，4k，高畫質，日系動漫",
        images: [
            "20240313_001/DALL·E 2024-03-13 09.59.19 - Imagine a scene at a sunny beach with perfect weather. A woman around 30 years old, dressed casually and wearing sunglasses, is lying on a beach chair.webp",
            "20240313_001/DALL·E 2024-03-13 09.59.57 - Imagine a scene at a sunny beach with perfect weather. A woman around 30 years old, dressed casually and wearing sunglasses, is lying on a beach chair.webp"
        ]
    },
    {
        description: "使用元素流暢過渡的雙重曝光圖像設計，大台電動速克達摩托車 kymco g-dink 300，女性騎士，穿著兩截式運動衣服飾, 水花效果,白煙霧,哥德式風格雕刻圖案,平面緞帶,搭配「MUDIK LEBARAN」內部3D書寫,充滿活力的色彩效果。 錯綜複雜的細節、輪廓和紋理非常真實,而且非常清楚,非常高品質、UHDR、超現實主義。",
        images: [
            "20240327_001/DALL·E 2024-03-27 18.27.51 - Create a double exposure image design where elements transition smoothly, featuring a KYMCO G-Dink 300 electric scooter and a female rider. The rider .webp",
            "20240327_001/DALL·E 2024-03-27 18.28.44 - Create a double exposure image design where elements transition smoothly, featuring a KYMCO G-Dink 300 electric scooter and a female rider. The rider .webp"
        ]
    },
    {
        description: "A hyper-realistic, semi-transparent glass pink-white lotus, yellow stamens visible, green stem, with refined details, viewed sideways from a high-angle, in a close-up focusing from bottom to top, Stunning light, against a clean black background, Inspiration: The art of blowing colorful glass.",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.47.26 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp",
            "20240329_001/DALL·E 2024-03-29 22.48.00 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃粉白蓮花，黃色花蕊可見，綠色莖，細節精緻，從高角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制彩色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.48.31 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp",
            "20240329_001/DALL·E 2024-03-29 22.48.48 - Imagine a hyper-realistic image of a semi-transparent glass lotus, with pink-white petals and visible yellow stamens, attached to a green stem. The fl.webp"
        ]
    },
    {
        description: "超寫實的半透明畫風，年紀23歲可愛少女跳舞，細節精緻，從低角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下白色背景，靈感：吹制彩色玻璃的藝術",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.49.05 - Envision a hyper-realistic, semi-transparent style image of a 23-year-old cute girl dancing. The details are refined and viewed from a low angle side .webp",
            "20240329_001/DALL·E 2024-03-29 22.49.18 - Envision a hyper-realistic, semi-transparent style image of a 23-year-old cute girl dancing. The details are refined and viewed from a low angle side .webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃玉米，細節精緻，從高角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制彩色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.49.51 - Visualize a hyper-realistic, semi-transparent glass corn cob, with exquisite details, viewed from a high angle and focusing from bottom to top against.webp",
            "20240329_001/DALL·E 2024-03-29 22.50.05 - Visualize a hyper-realistic, semi-transparent glass corn cob, with exquisite details, viewed from a high angle and focusing from bottom to top against.webp"
        ]
    },
    {
        description: "超寫實的半透明玻璃透明玉米，細節精緻，從低角度側視，特寫從下到上聚焦，令人驚嘆的光線，在乾淨的背景下黑色背景，靈感：吹制灰色玻璃的藝術。",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.50.44 - Visualize a photo-realistic image of a cute, fluffy little white cat with extremely fine detail and high clarity. The cat is captured in a real-life s.webp",
            "20240329_001/DALL·E 2024-03-29 22.51.04 - Visualize a photo-realistic image of a cute, fluffy little white cat with extremely fine detail and high clarity. The cat is captured in a real-life s.webp",
        ]
    },
    {
        description: "可愛的小白貓，身體圓圓的，頭圓圓的，四隻腳圓圓的，毛絨絨的，極細緻，高清晰，真實的拍攝，乾淨的黑色背景",
        images: [
            "20240329_001/DALL·E 2024-03-29 22.51.33 - Visualize a photo-realistic image of an adorable little white cat with a round body, round head, and round paws, all covered in fluffy, extremely fine.webp",
            "20240329_001/DALL·E 2024-03-29 22.51.45 - Visualize a photo-realistic image of an adorable little white cat with a round body, round head, and round paws, all covered in fluffy, extremely fine.webp"
        ]
    },
    {
        description: "美式大貨車車頭特寫，極細緻，高畫質，8k",
        images: [
            "20240403_001/DALL·E 2024-04-03 20.27.10 - A close-up of an American-style semi-truck cab, depicted in extreme detail and high definition, with an 8K quality resolution. The image captures the .webp",
            "20240403_001/DALL·E 2024-04-03 20.27.44 - A close-up of an American-style semi-truck cab, depicted in extreme detail and high definition, with an 8K quality resolution. The image captures the .webp"
        ]
    },
    {
        description: "一個可愛、肥胖的台灣中年大叔，穿著白色細背心，短髪，努力的在電腦前寫程式，桌上有大杯咖啡，桌上的平板和手機響不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，在灰暗的工作室裡為了一家人的生計拼命的打字，真實風格，高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.47.59 - A cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, is working hard on programming at a computer. He has a large cup o.webp",
            "20240628_001/DALL·E 2024-06-28 13.48.38 - A cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, is working hard on coding at a computer. On the desk, there is a l.webp",
        ]
    },
    {
        description: "一個可愛、肥胖的台灣中年大叔，穿著白色細背心，短髪，努力的在電腦前寫程式，桌上有大杯咖啡，桌上的平板和手機響不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，在灰暗的工作室裡為了一家人的生計拼命的打字，真實風格，高畫質，更真實一點的，要像是拍照的效果",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.49.53 - A realistic, high-definition photo of a cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, working hard on programming .webp",
            "20240628_001/DALL·E 2024-06-28 13.50.00 - A realistic, high-definition photo of a cute, chubby middle-aged Taiwanese man with short hair, wearing a white tank top, working hard on programming .webp",
        ]
    },
    {
        description: "畫個圖一個美麗的台灣美女，穿著白色細肩帶背心，長髪，努力的在電腦前寫程式，在悶熱的室內滿身大汗，桌上有大杯冰咖啡，手機閃個不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，為了一家人的生計拼命的打字，真實攝影風格，4k高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.50.28 - A beautiful Taiwanese woman with long hair, wearing a white tank top, working hard on programming at a computer in a hot indoor environment, sweating .webp",
        ]
    },
    {
        description: "一個美麗的台灣帥爸爸，穿著白色細肩帶背心，長髪，努力的在電腦前寫程式，在悶熱的室內滿身大汗，桌上有大杯冰咖啡，手機閃個不停，被逼瘋仍苦笑著，顧著身後的兩個小女孩，為了一家人的生計拼命的打字，真實攝影風格，4k高畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.50.47 - A handsome Taiwanese father with long hair, wearing a white tank top, working hard on programming at a computer in a hot indoor environment, sweating .webp"
        ]
    },
    {
        description: "建立尺寸 19:9 寬攝影畫面，從左後方拍攝鏡頭，從低向高拍攝視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，有一個投影幕前，瑩幕上顯示著控制台畫面，畫面中有IOT裝置、清單、報表，畫面最上方有個大大的藍色LED燈管的 「MQTT」 字樣，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.51.22 - A 19_9 wide-angle photograph taken from the left rear, looking up from a low angle, featuring a short-haired software manager wearing a suit vest. The.webp",
            "20240628_001/DALL·E 2024-06-28 13.51.22 - A 19_9 wide-angle photograph taken from the left rear, looking up from a low angle, featuring a short-haired software manager wearing a suit vest. The.webp"
        ]
    },
    {
        description: "建立一個畫面，一個穿著西裝背心的短髪軟體經理，，從左後方拍攝鏡頭，從低向高視角，坐在辦公室裡，最上方有個大大的藍色LED燈管的 「FLH MQTT」 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.51.49 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
            "20240628_001/DALL·E 2024-06-28 13.51.55 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
        ]
    },
    {
        description: "再修正一下，建立一個畫面，一個穿著西裝背心的短髪軟體經理，，背對著鏡頭，坐在一個投影大瑩幕前，大瑩幕上顯示著幾個控制台畫面，畫面中有IOT裝置、清單、報表，畫面最上方有個大大的藍色led燈管的 「MQTT」 字樣，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.52.10 - A short-haired software manager wearing a suit vest, sitting with his back to the camera in front of a large projection screen. The screen displays se.webp",
            "20240628_001/DALL·E 2024-06-28 13.52.16 - A short-haired software manager wearing a suit vest, sitting with his back to the camera in front of a large projection screen. The screen displays se.webp",
        ]
    },
    {
        description: "添加一下元素，建立一個畫面，從左後方拍攝鏡頭，從低向高視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，最上方有個藍色LED燈管的 \"FLH\"\"MQTT\" 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.52.32 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
            "20240628_001/DALL·E 2024-06-28 13.52.38 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear, looking up from a low angle. At the t.webp",
        ]
    },
    {
        description: "建立一個畫面，從左後方45度角拍攝鏡頭，從低向高視角，一個穿著西裝背心的短髪軟體經理，坐在辦公室裡，最上方有個藍色LED燈管的 \"FLH\"\"MQTT\" 招牌，有一個投影幕，瑩幕上顯示著控制台畫面，畫面中有 IOT 裝置、清單、報表，真實攝影，高清4K畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.53.10 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear at a 45-degree angle, looking up from .webp",
            "20240628_001/DALL·E 2024-06-28 13.53.15 - A short-haired software manager wearing a suit vest, seated in an office. The photo is taken from the left rear at a 45-degree angle, looking up from .webp",
        ]
    },
    {
        description: "天氣太熱，在可愛的小房間裡，房間是紛紅色系，一隻可愛的毛絨絨白色免子躺在沙灘椅上吹著冷氣，滿足的笑著，牆上不要有多餘的東西只有吹出冷風的空調冷氣，真實攝影，可愛卡通風格，高清8k畫質",
        images: [
            "20240628_001/DALL·E 2024-06-28 13.55.47 - In a cute little room with a pink color scheme, a fluffy white rabbit is lying on a beach chair, enjoying the cool air from an air conditioner. The ra.webp",
            "20240628_001/DALL·E 2024-06-28 13.55.58 - In a cute little room with a pink color scheme, a fluffy white rabbit is lying on a beach chair, enjoying the cool air from an air conditioner. The ra.webp",
        ]
    },

    /*
    {
        description: "",
        images: [
            "",
            "",
            "",
            "",
        ]
    },
*/
];
