import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
    useEffect(() => {
        document.title = 'Home Page';
    }, []);

    return (
        <>
            <Link to="/bingbox">Bing Gallery</Link>
            <Link to="/dallebox">DALL-E Gallery</Link>
        </>
    );
};

export default HomePage;