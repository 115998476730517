// App.tsx
import React, {useState, useEffect} from 'react';

// import ReactDOM from 'react-dom';
import {
    useParams
} from 'react-router-dom';

import DalleContentDisplay from './DalleContentDisplay'; // 导入内容显示组件
import Menu from '../Menu'; // 导入菜单组件
import Pagination from '../Pagination'; // 导入分页组件
import {menuLinks, topicsPerPage} from '../../data/data'; // 假设你的数据是从这里导入的
import {dalleContentItems} from "../../data/dalle";


const BingBox: React.FC = () => {
    let { pageNumber = '1' } = useParams(); // 默认页码为1

    const [currentItems, setCurrentItems] = useState(dalleContentItems.slice(dalleContentItems.length+1, -1));

    // const currentPage = parseInt(pageNumber || '1', 10);

    const totalPages = Math.ceil(dalleContentItems.length / topicsPerPage);
    const currentPage = parseInt(pageNumber, 10) || 1;
    const safeCurrentPage = isNaN(currentPage) || currentPage < 1 ? 1 : currentPage > totalPages ? totalPages : currentPage;

    useEffect(() => {

        const indexOfLastItem = safeCurrentPage * topicsPerPage;
        const indexOfFirstItem = indexOfLastItem - topicsPerPage;

        document.title = `Show DALL-E Gallery - Page ${safeCurrentPage}`;

        setCurrentItems(dalleContentItems.slice(indexOfFirstItem, indexOfLastItem));

    }, [safeCurrentPage]);

    return (
        <>
            <Menu links={menuLinks}/>
            <Pagination pathis="dallebox" totalPages={totalPages} currentPage={safeCurrentPage}/>
            <DalleContentDisplay items={currentItems}/>
            <Pagination pathis="dallebox" totalPages={totalPages} currentPage={safeCurrentPage}/>
        </>
    );
};

export default BingBox;