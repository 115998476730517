import React from 'react';

import { useNavigate } from 'react-router-dom';

interface PaginationProps {
    pathis: string;
    totalPages: number;
    currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ pathis, totalPages, currentPage }) => {
    let navigate = useNavigate();
    const goToPage = (pageNumber: number) => {
        navigate(`/${pathis}/${pageNumber}`);
    };

    return (
        <>
        <hr />
        <nav className="pages">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => goToPage(1)} disabled={currentPage === 1}>
                        &nbsp;&lt;&lt;&nbsp;
                    </button>
                </li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
                        &nbsp;&lt;&nbsp;
                    </button>
                </li>

                {[...Array(totalPages)].map((_, index) => {
                    console.log("page:"+index);
                    const pageNumber = index + 1;

                    return (
                        <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                            <button onClick={() => goToPage(pageNumber)}>
                                {" "+pageNumber+" "}
                            </button>
                        </li>
                    );
                })}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
                        &nbsp;&gt;&nbsp;
                    </button>
                </li>
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>
                        &nbsp;&gt;&gt;&nbsp;
                    </button>
                </li>
            </ul>
        </nav>
    <hr />
        </>
    );
};

export default Pagination;
