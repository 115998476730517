import React from 'react';

import {DalleContentItem} from "../../data/dalle";


// ContentDisplay组件，接收BingContentItem数组作为props
const DalleContentDisplay: React.FC<{ items: DalleContentItem[] }> = ({ items }) => {

    return (
        <>
            {items.map((item, index) => (
                <div key={index} className="content-item">
                    <p className="prompt-description">{item.description}</p>
                    <div className="images">
                        {item.images.map((image, index) => (
                            <a href={`/images/dalle/${image}`}><img key={index} src={`/images/dalle/${image}`}
                                                                   alt={`/images/dalle/${image}`}
                                                                   title={`#${index + 1} of ${item.description}`}/></a>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default DalleContentDisplay;
